import React from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import { setSearchTerm } from "./app/actions"

import styles from "./Autocomplete.styles"

const useStyles = makeStyles(theme => styles(theme))

const mapStateToProps = state => ({
  searchTerm: state.ProductSearchSettings.term,
})

const mapDispatchToProps = dispatch => ({
  setSearchTerm: term => dispatch(setSearchTerm(term)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function Autocomplete({ products, activeSuggestionIndex }) {
  const classes = useStyles()

  return (
    <datalist id="search" className={classes.Autocomplete}>
      {products.map((product, index) => (
        <option
          aria-label={product.node.title}
          key={product.node.id}
          className={`suggestion ${
            activeSuggestionIndex === index ? "is-selected" : ""
          }`}
          value={product.node.title}
        ></option>
      ))}
    </datalist>
  )
}

export default connector(Autocomplete)
