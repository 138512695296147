import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"

import { fetchProducts, setSearchResults, setSort } from "./app/actions"
import { searchQueryObject } from "./data"
import { sortingMethod, filterByTitleAndMetaTags } from "./helpers"

import NoMatch from "./NoMatch"
import Results from "./Results"
import Input from "./Input"
import Autocomplete from "./Autocomplete"

import styles from "./index.styles.js"

const useStyles = makeStyles(theme => styles(theme))

const mapStateToProps = state => ({
  productSearchSettings: state.ProductSearchSettings,
})

const mapDispatchToProps = dispatch => ({
  setSearchResults: results => dispatch(setSearchResults(results)),
  setSort: sort => dispatch(setSort(sort)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function ProductSearch({ allWpProduct, productSearchSettings, setSearchResults, setSort }) {
  const [isSearching, setIsSearching] = useState(false)
  const [performedSearch, setPerformedSearch] = useState(false)

  const classes = useStyles()

  function fetchProductsHandler(term, next = "", previousProducts = []) {
    setIsSearching(true)

    return fetchProducts(searchQueryObject, next)
      .then(data => ({
        products: data.products.filter(product =>
          filterByTitleAndMetaTags(product, term)
        ),
        next: data.next,
        hasNextPage: data.hasNextPage,
      }))
      .then(data => {
        const nextProducts = [...data.products, ...previousProducts]
        const { hasNextPage } = data
        if (hasNextPage) {
          fetchProductsHandler(term, data.next, nextProducts)
        } else {
          setSearchResults(nextProducts)
          setIsSearching(false)
          setPerformedSearch(true)
        }
      })
  }

  function handleClickSort(e) {
    const order = e.target.value
    setSort(order)
  }

  function handleSearchFormSubmit(e) {
    if (e) e.preventDefault()

    fetchProductsHandler(e.target.elements[0].value)
  }

  function handleSearchFormChange(e) {
    const foundSearchedTerm = allWpProduct.find(
      product =>
        product.node.title.toLowerCase() === e.target.value.toLowerCase()
    )

    if (foundSearchedTerm) {
      fetchProductsHandler(e.target.value.toLowerCase())
    }
  }

  const noResults = performedSearch && !isSearching && !productSearchSettings.results.length
  const sortedProducts = productSearchSettings.results.toSorted(
      sortingMethod(productSearchSettings.sort)
  )

  return (
    <div className="wrapper">
      <div className={classes.searchBar}>
        <h1>Product Search</h1>
        <div className="form-wrap">
          <form
            className="search-form"
            autoComplete="off"
            onSubmit={e => handleSearchFormSubmit(e)}
            onChange={e => handleSearchFormChange(e)}
          >
            <Input />
            <Autocomplete products={allWpProduct} />
            <button type="submit" aria-label="Search" disabled={isSearching}>
              Search
            </button>
          </form>

          {isSearching && <p>Searching ...</p>}
        </div>
      </div>

      {sortedProducts.length > 0 && !isSearching && (
        <div className={classes.results}>
          <Results
            products={sortedProducts}
            handleClickSort={handleClickSort}
            sort={productSearchSettings.sort}
          />
        </div>
      )}
      {noResults && <NoMatch />}
    </div>
  )
}

export default connector(ProductSearch)
