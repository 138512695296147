import { Link } from "gatsby"
import React from "react"

const NoMatch = () => (
  <p
    style={{
      color: "var(--primary-navy)",
      width: "100%",
      display: "block",
      position: "relative",
      fontSize: 16,
      maxWidth: 1142,
      marginTop: 0,
      fontFamily: '"Oxygen", sans-serif',
      lineHeight: 1.7,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 32,
      letterSpacing: "0.016em",
    }}
  >
    Unfortunately, your search hasn’t matched any of our available products.
    <br />
    <br />
    If you have any difficulty finding what you are searching for, please{" "}
    <Link
      to="/contact-us"
      style={{
        color: "#009f4d",
        textDecoration: "underline !important",
        fontWeight: "bold",
      }}
    >
      contact us
    </Link>{" "}
    and we will do our best to help.
  </p>
)

export default NoMatch
