import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ProductSearch from "../components/ProductSearch/index.js"
import { isBrowser } from "../helpers"
import CookiesConsent from "../components/CookiesConsent"

export default function ProductSearchPage({ data }) {
  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/product-search/",
      }}
      path={"/product-search"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      {isBrowser && (
        <ProductSearch allWpProduct={data.allWpProduct.edges}></ProductSearch>
      )}
      {!isBrowser && <p>Loading...</p>}
      <CookiesConsent></CookiesConsent>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    allWpProduct(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
