import React from "react"
import { makeStyles } from "@material-ui/styles"

import ProductsGrid from "../Products/Components/ProductsGrid"
import styles from "./Results.styles"

const useStyles = makeStyles(theme => styles(theme))

const Results = ({ products, handleClickSort, sort }) => {
  const classes = useStyles()
  return (
    <div className={classes.results}>
      <div className="result-nav">
        <span className="count">
          <strong>Products:</strong> {products.length}{" "}
          {products.length === 1 ? "item" : "items"}
        </span>
        <div className="sort-wrap">
          <div className="select-row">
            <span>Sort by</span>
            <select onChange={e => handleClickSort(e)} value={sort}>
              <option value="name-asc" className="up">
                Name (A-Z)
              </option>
              <option value="name-desc" className="down">
                Name (Z-A)
              </option>
              <option value="price-asc" className="up">
                Price (Low to high)
              </option>
              <option value="price-desc" className="down">
                Price (High to low)
              </option>
            </select>
          </div>
        </div>
      </div>
      <ProductsGrid classes={classes} products={products} />
    </div>
  )
}

export default Results
