import axios from "axios"

import { SET_SORT, SET_SEARCH_RESULTS, SET_SEARCH_TERM } from "./types"

export function setSearchResults(results) {
  return {
    type: SET_SEARCH_RESULTS,
    results,
  }
}

export function setSort(sort) {
  return {
    type: SET_SORT,
    sort,
  }
}

export function setSearchTerm(term) {
  return {
    type: SET_SEARCH_TERM,
    term,
  }
}

export function fetchProducts(queryObject, next = "") {
  return axios
    .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObject(next), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res => {
      return {
        products: res.data.data.products.edges.map(node => node.node),
        next: res.data.data.products.pageInfo.endCursor,
        hasNextPage: res.data.data.products.pageInfo.hasNextPage,
      }
    })
}
