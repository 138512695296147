export const queryObject = (next = "") => {
  return {
    query: `
    {    
      products(first: 50, after: "${next}") {
            edges {
              node {
                id
                link
                slug
                title
                uri
                ProductData {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 920
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                      )
                    }
                  }
                  description                 
                  productId
                  price
                  options
                  title
                  featuredImage
                  variants {
                    inStock
                    comparePrice
                    image
                    option1
                    option2
                    option3
                    price
                    quantity
                    sku
                    title
                    weight
                    variantIdBase64
                  }
                }
                ProductMeta {
                  assemblyTime
                  assemblyDifficulty
                  availableToConsumers
                  availableToTrade
                  bestseller
                  careAndMaintenance
                  delivery
                  dimensions
                  fscCertified
                  keywords
                  materials
                  personsToAssemble
                  shortDescription
                  shippingBoxes
                  preservativeTreatment
                  madeInBritain
                  warrantyPeriod
                  videos {
                    url
                  }
                  pdfs {
                    url
                  }
                  features {
                    text
                  }
                }
              }
            }
            pageInfo {              
              hasNextPage
              endCursor              
            }
        }
    }

  `,
  }
}

export const searchQueryObject = (next = "") => {
  return {
    query: `
    {    
      products(first: 50, after: "${next}") {
            edges {
              node {
                id
                link
                slug
                title
                uri
                ProductData {
                  description                 
                  productId
                  price
                  options
                  title
                  featuredImage
                  variants {
                    inStock
                    comparePrice
                    image
                    option1
                    option2
                    option3
                    price
                    quantity
                    sku
                    title
                    weight
                    variantIdBase64
                  }
                }
                ProductMeta {
                  assemblyTime
                  assemblyDifficulty
                  availableToConsumers
                  availableToTrade
                  bestseller
                  careAndMaintenance
                  delivery
                  dimensions
                  certification
                  keywords
                  materials
                  personsToAssemble
                  shortDescription
                  shippingBoxes
                  preservativeTreatment
                  madeInBritain
                  warrantyPeriod
                  videos {
                    url
                  }
                  pdfs {
                    url
                  }
                  features {
                    text
                  }
                }
              }
            }
            pageInfo {              
              hasNextPage
              endCursor              
            }
        }
    }

  `,
  }
}
