import arrow from "../../images/select-arrow.webp"

const styles = theme => {
  return {
    results: {
      "& select": {
        borderRadius: 4,
        color: theme.palette.secondary.beige,
        border: "1px solid " + theme.palette.secondary.beige,
        fontSize: 16,
        letterSpacing: "0.016em",
        padding: "17px 45px 17px 20px",
        lineHeight: 1.1,
        fontFamily: theme.bodyFont,
        backgroundImage: "url(" + arrow + ")",
        backgroundPosition: "center right 15px",
        backgroundSize: "10px 6px",
        backgroundRepeat: "no-repeat",
        appearance: "none",
        outline: "none",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        "-ms-appearance": "none",
        [theme.breakpoints.up("md")]: {
          minWidth: 0,
        },
      },
    },
  }
}

export default styles
