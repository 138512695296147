import React from "react"
import { connect } from "react-redux"

import { setSearchTerm } from "./app/actions"

const mapStateToProps = state => ({
  searchTerm: state.ProductSearchSettings.term,
})

const mapDispatchToProps = dispatch => ({
  setSearchTerm: term => dispatch(setSearchTerm(term)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

function Input({ searchTerm = "", setSearchTerm }) {
  return (
    <input
      list="search"
      placeholder="Enter product name or SKU"
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
    />
  )
}

export default connector(Input)
