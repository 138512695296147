import searchIcon from "../../images/search-icon.webp"
import arrow from "../../images/select-arrow.webp"
import { isBrowser } from "../../helpers"

// eslint-disable-next-line
export default theme => {
  if (!isBrowser) return null
  return {
    searchBar: {
      flex: "0 0 100%",
      paddingTop: 30,
      [theme.breakpoints.up("lg")]: {
        paddingTop: 0,
      },
      "& h1": {
        display: "block",
        position: "relative",
        marginTop: 0,
        maxWidth: 1142,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: theme.headerFont,
        padding: "0 12px",
        color: theme.palette.primary.navy,
        fontSize: 32,
        lineHeight: 1.25,
        fontWeight: "bold",
        letterSpacing: "-0.024em",
        margin: "32px 0",
        [theme.breakpoints.up("sm")]: {
          fontSize: 44,
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: 56,
          margin: "50px 0",
        },
      },
      "& .form-wrap": {
        position: "relative",
        width: "calc(100% - 24px)",
        margin: "0 12px 50px",
        backgroundColor: "#E4DFD9",
        padding: 32,
        "& p": {
          display: "block",
          textAlign: "center",
        },
        "& .search-form": {
          width: "100%",
          margin: "0 auto",
          maxWidth: 550,
          position: "relative",
          "& label": {
            display: "block",
            fontFamily: theme.bodyFont,
            fontWeight: "bold",
            fontSize: 12,
            letterSpacing: "0.152em",
            lineHeight: 1.33,
            color: theme.palette.primary.purple,
            textTransform: "uppercase",
            marginBottom: 5,
          },
          "& input": {
            borderRadius: 25,
            padding: "15px 30px",
            fontSize: 14,
            lineHeight: 1,
            fontFamily: theme.bodyFont,
            letterSpacing: "0.016em",
            backgroundColor: "#fff",
            border: "none",
            width: "100%",
            height: 50,
            paddingRight: 76,
            position: "relative",
            zIndex: 1,
            outline: "none !important",
          },
          "& button": {
            bakgroundColor: theme.palette.primary.green,
            border: "none",
            borderRadius: "50%",
            fontSize: 0,
            backgroundImage: "url(" + searchIcon + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: 46,
            height: 46,
            position: "absolute",
            bottom: 2,
            right: 2,

            "&:disabled": {
              opacity: 0.5,
              filter: "grayscale(1)",
            },
            zIndex: 2,
          },
        },
      },
    },
    results: {
      width: "calc(100% - 24px)",
      margin: "0 auto 32px",
      "& .MuiGrid-grid-sm-6.MuiGrid-grid-lg-4": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 25%",
        },
      },
      "& .result-nav": {
        position: "relative",
        marginBottom: 30,
        [theme.breakpoints.up("md")]: {
          marginTop: 50,
          borderBottom: "none",
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .count": {
          fontSize: 16,
          letterSpacing: "0.016em",
          lineHeight: 2,
          fontWeight: "normal",
          color: theme.palette.primary.navy,
          fontFamily: theme.bodyFont,
        },
        "& .sort-wrap": {
          backgroundColor: theme.common.white,
          marginTop: 12,
          overflow: "hidden",
          height: 0,
          opacity: 0,
          transition: "opacity .3s ease-in-out",
          "&[open]": {
            height: "auto",
            opacity: 1,
          },
          [theme.breakpoints.up("md")]: {
            height: "auto !important",
            opacity: "1 !important",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
          },
          "& .select-row": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "12px 12px 0",
            alignItems: "center",
            [theme.breakpoints.up("md")]: {
              padding: 12,
            },
            "& span": {
              fontSize: 12,
              fontFamily: theme.bodyFont,
              color: theme.palette.primary.navy,
              letterSpacing: "0.152em",
              lineHeight: "32px",
              textTransform: "uppercase",
              fontWeight: "bold",
              marginRight: 12,
            },
            "& button": {
              borderRadius: 4,
              cursor: "pointer",
              color: theme.palette.secondary.beige,
              border: "1px solid " + theme.palette.secondary.beige,
              fontSize: 16,
              backgroundColor: "white",
              letterSpacing: "0.016em",
              padding: "17px 20px",
              lineHeight: 1.1,
              fontFamily: theme.bodyFont,
              appearance: "none",
              outline: "none",
              [theme.breakpoints.up("md")]: {
                minWidth: 0,
              },
              "& span": {
                backgroundImage: "url(" + arrow + ")",
                display: "inline-block",
                width: 10,
                height: 6,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                marginLeft: 10,
                marginBottom: 2,
                marginRight: 0,
                "&.down": {
                  transform: "scaleY(-1)",
                },
              },
            },
            "&.show-count": {
              paddingBottom: 12,
            },
          },
        },
      },
    },
  }
}
