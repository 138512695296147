export default function AutocompleteStyles(theme) {
  return {
    Autocomplete: {
      position: "absolute",
      top: 25,
      left: 0,
      right: 0,
      zIndex: 0,
      backgroundColor: "var(--global-white)",
      paddingTop: 40,
      paddingBottom: 12,
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow: "0 0 12px rgba(0,0,0,0.12)",
      overflow: "hidden",

      "& .suggestion": {
        display: "block",
        cursor: "default",
        width: "100%",
        padding: "6px 20px",
        color: "var(--global-black)",
        "&:hover": {
          backgroundColor: "var(--primary-green)",
          color: "var(--primary-navy)",
        },
      },
    },
  }
}
