export function sortingMethod(order) {
  if (order === "name-asc") {
    return (a, b) => a.title.localeCompare(b.title)
  }

  if (order === "name-desc") {
    return (a, b) => b.title.localeCompare(a.title)
  }

  if (order === "price-asc") {
    return (a, b) => a.ProductData.price - b.ProductData.price
  }

  if (order === "price-desc") {
    return (a, b) => b.ProductData.price - a.ProductData.price
  }
}

export function filterByTitleAndMetaTags(product, term) {
  if (product.ProductData.variants.map(variant => variant.sku).includes(term)) {
    return true
  }
  if (product?.title?.toLowerCase().includes(term?.toLowerCase())) return true
  if (product.ProductMeta.keywords) {
    if (product.ProductMeta.keywords.split(",").includes(term)) {
      return true
    }
  }
  return false
}
